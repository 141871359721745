/* this file is transformed by vux-loader */
/* eslint-disable */
import { format, keepTwoDecimalFull } from "../../../assets/app.js";
export default {
  props: {
    wpInfo: {
      default: ""
    }
  },
  data() {
    return {
      showDataJH: false,
      showDataXM: false,
      currentDate: new Date(),
      datas: {}
    };
  },
  methods: {
    onConfirmJH(timestamp) {
      this.wpInfo.jhrq = format(timestamp);
      this.showDataJH = false;
    },
    onConfirmXM(timestamp) {
      this.wpInfo.xmrq = format(timestamp);
      this.showDataXM = false;
    },
    onProps() {
      this.datas = this.wpInfo;
      this.datas._state = "added";
      this.$emit("wpListInfo", this.datas);
    }
  }
};