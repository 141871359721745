/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.array.push.js";
// import { getTestTable } from "../../api/index";

import { unique } from "@/assets/app.js";
import QjhcInfo from "./QjhcInfo";
import { Dialog } from 'vant';
export default {
  components: {
    QjhcInfo
  },
  data() {
    return {
      showInfo: false,
      dataList: [],
      mount: [],
      mountDupRe: [],
      pageNum: 1,
      prePage: 1,
      maxPageNum: 1,
      totalNum: 0,
      // pageIndex: 0,
      pageSize: 20,
      result: [],
      itemString: "",
      dataInfo: {}
    };
  },
  created() {
    this.getCgwpInfo();
    this.data = this.mount;
  },
  mounted() {},
  methods: {
    getCgwpInfo() {
      var pageNum = this.pageNum - 1;
      var formData = new FormData();
      formData.append("pageIndex", pageNum);
      formData.append("pageSize", this.pageSize);
      var url = _baseUrl + `/equipmentMgmt/equipment/eQUIPMENT/listData.do`;
      this.$ajax.post(url, formData).then(res => {
        this.dataList = res.data.data;
        this.dataList.map(item => {
          if (JSON.stringify(this.mount).indexOf(item.identifyLabel) == -1) {
            this.mount.push(item);
          }
        });
        this.totalNum = res.data.total;
        this.maxPageNum = Math.ceil(this.totalNum / 20);
        console.log(this.mount.length);
      });
    },
    onPlus() {
      this.pageNum += 1;
      this.getCgwpInfo();
    },
    onMinus() {
      this.pageNum -= 1;
      this.getCgwpInfo();
    },
    onChange() {
      document.onkeydown = e => {
        var pageNum = this.pageNum;
        var maxPageNum = this.maxPageNum;
        if (pageNum <= maxPageNum) {
          // this.prePage = pageNum;
          this.getCgwpInfo();
        } else {
          Dialog({
            message: '请正确输入页数！'
          });
        }
      };
    },
    goodsInfo(item) {
      this.showInfo = true;
      this.dataInfo = item;
    },
    //确定
    onSelects() {
      // var result = JSON.stringify(this.result).toString().replace(new RegExp("\\\\\"","gm"),"\"");
      // var json = eval("(" + result + ")");
      // console.log(this.result);
      //数组对象去重
      // let mount = unique(this.mount);
      let result = [];
      this.mount.map(item => {
        this.result.map(pkid => {
          if (pkid == item.pkId) {
            result.push(item);
          }
        });
      });
      this.$emit("cgwpList", result);
    }
  }
};