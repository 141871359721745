/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.array.push.js";
import axios from "axios";
import LCFQheader from "../../LCFQheader";
import QjhcTalbe from "../../../common/sbgl/QjhcTalbe";
import QjhcAddInfo from "../../../common/sbgl/QjhcAddInfo";
import { RxUtil } from "@/assets/util.js";
import { timesFnt, unique, format, formatHMT } from "@/assets/app.js";
export default {
  name: "lcfqQjhc",
  components: {
    LCFQheader,
    QjhcTalbe,
    QjhcAddInfo
  },
  data() {
    return {
      oneft: "/index",
      headTit: "新建申请",
      tit: "设备期间核查计划",
      showDataJH: false,
      showsyqck: false,
      minDate: new Date(2020, 0, 1),
      currentDate: new Date(),
      showListpage: false,
      showGinfo: false,
      userID: "",
      solId: "",
      actDefId: "",
      resultFa: [],
      cgwpDatas: [],
      wpInfoData: {},
      datas: {
        jhmc: '',
        jhbh: "",
        jhzdrName: "",
        jhrq: ""
      },
      cgsqrT: new Date()
    };
  },
  created() {
    this.initBySolInstId();
    this.cgORxj();
  },
  methods: {
    initBySolInstId() {
      let solId = this.$route.params.solId;
      let instId = this.$route.params.instId;
      var url = _baseUrl + "/mobile/bpm/startForm.do?solId=" + solId;
      this.$ajax.post(url).then(response => {
        this.solId = response.data.bpmSolution.solId;
        this.actDefId = response.data.bpmSolution.actDefId;
      });
      if (instId == 0) {
        let urlBh = _baseUrl + "/sys/core/sysSeqId/genNo_getHcJh.do?_=1644483840226";
        this.$ajax.post(urlBh).then(res => {
          this.datas.jhbh = res.data;
        });
        this.datas.jhzdrName = RxUtil.getCache("userName");
        this.datas.jhrq = format(new Date());
      }
    },
    async cgORxj() {
      let busad = this.$route.params.busad;
      let instId = this.$route.params.instId;
      if (instId != 0) {
        this.headTit = "我的草稿";
        this.oneft = "/myDrafts";
        var url = _baseUrl + "/equipmentMgmt/sbqjhcjh/qjHcJh/getJson.do?ids=" + busad;
        this.$ajax.post(url).then(res => {
          console.log("getJson.do?=", res);
          this.datas.jhzdrName = res.data.jhzdrName;
          this.datas.jhmc = res.data.jhmc;
          this.datas.hcnr = res.data.hcnr;
          this.datas.hczq = res.data.hczq;
          this.datas.jhbh = res.data.jhbh;
          this.datas.jhrq = res.data.jhrq;
        });
        let urlGet = _baseUrl + `/equipmentMgmt/sbqjhcjhxq/qjHcJhList/listData.do?Q_JHBH_S_LK=${busad}&pageIndex=0&pageSize=50000`;
        this.$ajax.post(urlGet).then(resGet => {
          this.cgwpDatas = resGet.data.data;
        });

        //xzoa/equipmentMgmt/sbqjhcjhxq/qjHcJhList/listData.do  子表数据

        //var url = _baseUrl + "/customizeProc/purRequisition/purRequisition/getJson.do?ids="+busad;
        // this.$ajax.post(url).then(res=>{
        // 	this.datas = res.data;
        // })
        // let urlGet = _baseUrl + `/customizeProc/supIosTab/supIosTab/findByCgid.do?cgid=${busad}&pageIndex=0&pageSize=100000&sortField=&sortOrder`;
        // this.$ajax.post(urlGet).then(resGet=>{
        //   this.cgwpDatas = resGet.data.data;
        // })
      }
    },

    onConfirmJH(timestamp) {
      this.datas.jhrq = format(timestamp);
      this.cgsqrT = formatHMT(timestamp);
      this.showDataJH = false;
    },
    showList() {
      this.showListpage = true;
    },
    cgwpList(list) {
      // console.log(list);
      // let cgwpData = [];
      // this.cgwpDatas.map((item)=>{
      //   cgwpData.push(item);
      // })
      // list.map((item)=>{
      //   cgwpData.push(item);
      // })
      // this.cgwpDatas = unique(cgwpData);
      // this.showListpage = false;

      this.cgwpDatas = list;
      console.log('增加了条目=', this.cgwpDatas);
      this.showListpage = false;
    },
    //查看具体信息
    goodsInfo(item) {
      console.log(item);
      this.showGinfo = true;
      this.wpInfoData = item;
    },
    //添加信息
    wpListInfo(item) {
      console.log(item);
      this.cgwpDatas.map(datai => {
        if (datai.pkId == item.pkId) {
          datai = item;
        }
      });
      console.log(this.cgwpDatas);
      this.showGinfo = false;
    },
    //删除
    delectWP() {
      // for(let i=0;i<this.resultFa.length;i++){
      //   for(let y=0;y<this.cgwpDatas.length;y++){
      //     if (this.resultFa[i] == this.cgwpDatas[y].pkId) {
      //       this.cgwpDatas.splice(this.cgwpDatas[y],1);
      //     }
      //   }
      // }

      console.log(this.resultFa); //选中的要删的
      console.log(this.cgwpDatas); //现有的
      for (let i = 0; i < this.resultFa.length; i++) {
        for (let y = 0; y < this.cgwpDatas.length; y++) {
          if (this.resultFa[i] == this.cgwpDatas[y].pkId) {
            this.cgwpDatas.splice(y, 1);
          }
        }
      }
      this.$refs.checkboxGroup.toggleAll(false);
    },
    upDatas() {
      if (this.datas.jhmc != "" && this.datas.jhrq != "" && this.datas.hczq != "" && this.datas.hcnr != "") {
        for (let i = 0; i < this.cgwpDatas.length; i++) {
          this.cgwpDatas[i]._id = i + 1;
          this.cgwpDatas[i]._uid = i + 1;
        }
        var jsonDataObj = {
          id: "",
          jhbh: this.datas.jhbh,
          jhzdrName: this.datas.jhzdrName,
          jhmc: this.datas.jhmc,
          jhrq: formatHMT(this.datas.jhrq),
          hczq: this.datas.hczq,
          hcnr: this.datas.hcnr,
          SUB_datagrid1: this.cgwpDatas
        };
        var jsonData = JSON.stringify(jsonDataObj);
        var params = new FormData();
        params.append("solId", this.solId);
        params.append("bpmInstId", "");
        params.append("jsonData", jsonData);
        return params;
      } else {
        this.errorToast("物品信息未完善！");
      }
    },
    //字段检查
    iN(str) {
      if (str == '' || str == null || typeof str == 'undefined') {
        return true;
      }
      return false;
    },
    //整体判断
    inspect() {
      if (this.iN(this.datas.jhbh) || this.iN(this.datas.jhmc) || this.iN(this.datas.jhzdrName) || this.iN(this.datas.hczq) || this.iN(this.datas.jhrq)) {
        return true;
      }
      return false;
    },
    onSubmit() {
      if (this.inspect()) {
        this.errorToast("启动失败！请检查信息是否填写完整", "1800");
        return;
      }
      if (this.cgwpDatas.length == 0) {
        this.errorToast("启动失败！请至少添加一条设备信息", "1800");
        return;
      }
      var params = this.upDatas();
      var url = _baseUrl + "/bpm/core/bpmInst/startProcess.do";
      this.$dialog.loading.open('正在启动流程,请稍候...');
      this.$ajax.post(url, params).then(res => {
        this.$dialog.loading.close();
        var data = res.data;
        if (data.success) {
          this.$store.commit('cleanInstData');
          this.successToast("启动成功！", 1000, 'index', {
            from: 'startForm'
          });
        } else {
          this.errorToast("启动失败！请检查信息是否填写完整", "1800");
        }
      });
    },
    onPreservation() {
      if (this.inspect()) {
        this.errorToast("保存失败！请检查信息是否填写完整", "1800");
        return;
      }
      var params = this.upDatas();
      var url = _baseUrl + "/bpm/core/bpmInst/saveDraft.do";
      this.$dialog.loading.open('正在保存草稿,请稍候...');
      this.$ajax.post(url, params).then(res => {
        this.$dialog.loading.close();
        var data = res.data;
        if (data.success) {
          this.$store.commit('cleanInstData');
          this.successToast("保存成功！", 1000, 'index', {
            from: 'startForm'
          });
        } else {
          this.errorToast("启动失败！请检查信息是否填写完整", "1800");
        }
      });
    },
    onLct() {
      this.$router.push({
        name: "procImage",
        params: {
          id: this.actDefId,
          type: 'start'
        }
      });
    }
  }
};